import React from 'react';
import ReactDOM from 'react-dom';

import { getCurrentScript } from 'tiny-current-script';
import { MOIN_SETTING_NAME, WINDOW_WIDGET, MoinSettingType } from './config';
import App from './EmbedApp';
import pubSub from './pubSub';

window[WINDOW_WIDGET] = {
    exec: pubSub.exec,
    listen: pubSub.subsrcibe,
};

const currentScript = getCurrentScript();
const isMobileFn = () => {
    const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    return width <= 1024;
};

if (!currentScript) {
    throw new Error('Unsupported browser, please upgrade!');
}
const pid = currentScript.getAttribute('data-pid') || '';
const isMobile = isMobileFn();

const renderApp = ({ domNodeId }: {domNodeId: string}) => {
    const moinSetting: MoinSettingType = window[MOIN_SETTING_NAME] ? window[MOIN_SETTING_NAME] : {};
    ReactDOM.render(<App pid={pid} isMobile={isMobile} moinSetting={moinSetting} />, document.getElementById(domNodeId));
};

function onDomReady() {
    const domNodeId = 'moin-dom-node';
    const idNode = document.createElement('div');
    idNode.setAttribute('id', domNodeId);
    document.body.appendChild(idNode);
    renderApp({ domNodeId });
}

if (document.readyState !== 'loading') {
    onDomReady();
} else {
    document.addEventListener('DOMContentLoaded', onDomReady);
}
