import * as React from 'react';
import { CiaoCiaoForm } from '@ctxhou/ciaociao-form-react';
import Frame from 'react-frame-component';
import {
    MESSAGE_BUTTON_VIEW,
    FORM_MODAL_VIEW,
    SUCCESS_MODAL_VIEW,
    FAILURE_MODAL_VIEW,
} from '@ctxhou/ciaociao-types/dist/formConfig';
import {
    MESSAGE_BUTTON_LABEL,
    FORM_LABEL,
    SHOW_EVENT,
    HIDE_EVENT,
    CLICK_EVENT,
    SUBMIT_EVENT,
    SENT_EVENT,
    FAILED_TO_SUBMIT_EVENT,
} from '@ctxhou/ciaociao-types/dist/dispatchActions';
import { version } from '@ctxhou/ciaociao-form-react/package.json';
import { showButton, showMessage, onSubmitData } from './iframe-utils';
import { ENDPOINT, CDN_ENDPOINT, MoinSettingType } from './config';
import { preload } from './preload';
import { submitToNetlify } from './utils';
import pubSub from './pubSub';

const styleLink = `${CDN_ENDPOINT}/style-${version}.css`;

type ViewType = typeof FORM_MODAL_VIEW |
     typeof FAILURE_MODAL_VIEW |
     typeof SUCCESS_MODAL_VIEW |
     typeof MESSAGE_BUTTON_VIEW |
     typeof FAILURE_MODAL_VIEW;

type Props = {
    pid: string,
    isMobile: boolean,
    moinSetting: MoinSettingType
};

interface PluginPayloadType {
    config: Array<Object>,
    locale: string,
    schemaId: string,
    netlifyFormName: string,
    uiConfig: Object
}

const App = ({ pid, isMobile, moinSetting }: Props) => {
    const [isShownModal, setIsShownModal] = React.useState(false);
    const [pluginConfig, setPluginConfig] = React.useState<PluginPayloadType>({} as PluginPayloadType);
    const [isFetched, setIsFetched] = React.useState(false);
    const [view, setView] = React.useState<ViewType>(MESSAGE_BUTTON_VIEW);

    const actionHook = (label: string, event: string, payload?: any) => {
        pubSub.publish(label, event, payload);
        if (label === MESSAGE_BUTTON_LABEL) {
            if (event === CLICK_EVENT) {
                setIsShownModal(true);
                showMessage();
                setView(FORM_MODAL_VIEW);
            }
        } else if (label === FORM_LABEL) {
            if (event === SUBMIT_EVENT) {
                submitToNetlify({ data: payload.data, netlifyFormName: pluginConfig.netlifyFormName, schema: pluginConfig.config });
                onSubmitData(payload.data);
            } else if (event === SENT_EVENT) {
                setView(SUCCESS_MODAL_VIEW);
            } else if (event === FAILED_TO_SUBMIT_EVENT) {
                setView(FAILURE_MODAL_VIEW);
            } else if (event === HIDE_EVENT) {
                setIsShownModal(false);
                setTimeout(() => {
                    showButton();
                }, 50);
            }
        }
    };

    React.useEffect(() => {
        preload(pid).then((json) => {
            if (json.result) {
                setPluginConfig(json.data);
                setIsFetched(true);
            }
        });
    }, [pid]);

    React.useEffect(() => {
        pubSub.internalSubscribe(FORM_LABEL, SHOW_EVENT, () => {
            setIsShownModal(true);
        });
        pubSub.internalSubscribe(FORM_LABEL, HIDE_EVENT, () => {
            setIsShownModal(false);
        });
    }, []);

    let frameStyle = {
        display: 'block',
        boxShadow: 'none',
        position: 'fixed',
        bottom: 0,
        right: 0,
        margin: '10px',
        zIndex: '1000000000000',
        border: 0,
        width: '160px',
        height: '50px',
    };
    const shouldHideMessageButtonOnMobile = isMobile ? moinSetting?.hideButtonOnMobileDevice : false;
    if (isShownModal) {
        if (isMobile) {
            frameStyle = {
                ...frameStyle,
                display: 'block',
                boxShadow: '0 18px 50px -10px rgba(0, 0, 0, 0.2)',
                width: '100%',
                height: '100%',
                margin: '0',
            };
        } else {
            frameStyle = {
                ...frameStyle,
                display: 'block',
                boxShadow: '0 18px 50px -10px rgba(0, 0, 0, 0.2)',
                width: '375px',
                height: '540px',
                margin: '10px',
            };
        }
    } else if (isMobile && shouldHideMessageButtonOnMobile) {
        frameStyle = {
            ...frameStyle,
            display: 'none',
        };
    }

    return (
        <Frame
            style={frameStyle}
            head={
                <link rel="stylesheet" href={styleLink} />
            }
        >
            {isFetched && pluginConfig
                ? (
                    <CiaoCiaoForm
                        endpoint={ENDPOINT}
                        pluginConfig={pluginConfig}
                        pid={pid}
                        isMobile={isMobile}
                        schemaId={pluginConfig.schemaId}
                        locale={pluginConfig.locale}
                        isShownModal={isShownModal}
                        view={view}
                        actionHook={actionHook}
                        shouldHideMessageButton={shouldHideMessageButtonOnMobile}
                    />
                )
                : null}
        </Frame>

    );
};

export default App;
