import {ENDPOINT} from './config';

export function preload(pid: string) {
  return fetch(`${ENDPOINT}/api/app/${pid}/plugin`, {
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
    },
  })
  .then(res => {
    return res.json();
  }).catch(() => {})
}
