/* eslint-disable import/prefer-default-export */
const encode = (data: any) => Object.keys(data)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&');

export const submitToNetlify = ({ data, schema, netlifyFormName }: {data: any, schema: any, netlifyFormName: string}) => {
    const moinForm = document.getElementById('moinForm');
    if (moinForm) {
        const dataWithLabelAsKey: any = {};
        const schemaIdToLabel: any = {};
        schema.forEach(({ id, labelText }: {id: string, labelText: string}) => {
            schemaIdToLabel[id] = labelText;
        });
        Object.keys(data).forEach((key) => {
            dataWithLabelAsKey[schemaIdToLabel[key]] = data[key];
        });
        fetch('/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: encode({ 'form-name': netlifyFormName, ...dataWithLabelAsKey }),
        })
            .then(() => console.log('Success!'))
            .catch((error) => console.log(error));
    }
};
