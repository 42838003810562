import PubSub from 'pubsub-js';
import {
    genLabelAndEventName,
} from '@ctxhou/ciaociao-types/dist/dispatchActions';

class MoinSubscribe {
    #subsrcibedEvents: Array<string>;

    constructor() {
        this.#subsrcibedEvents = [];
    }

    private genExecEvent = (label, event) => `exec:${label}:${event}`;

    subsrcibe = (label, event, cb) => {
        const subscribeEvent = genLabelAndEventName(label, event);
        if (!this.hasSubscribed(subscribeEvent)) {
            this.#subsrcibedEvents.push(subscribeEvent);
            PubSub.subscribe(subscribeEvent, cb);
        }
    };

    internalSubscribe = (label, event, cb) => {
        PubSub.subscribe(this.genExecEvent(label, event), cb);
    };

    exec = (label, event) => {
        PubSub.publish(this.genExecEvent(label, event));
    };

    private unsubscribe = (event, cb) => {
        if (this.hasSubscribed(event)) {
            PubSub.unsubscribe(event);
        }
    };

    publish = (label, event, payload) => {
        const subscribeEvent = genLabelAndEventName(label, event);
        if (this.hasSubscribed(subscribeEvent)) {
            PubSub.publish(subscribeEvent, payload);
        }
    };

    private hasSubscribed = (subscribeEvent) => this.#subsrcibedEvents.includes(subscribeEvent);
}

export default new MoinSubscribe();
