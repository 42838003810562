export function getParent() {
    return typeof window !== 'undefined' && window.parent;
}

export function showButton() {
    const parent = getParent();
    if (parent) {
        window.parent.postMessage({ type: 'ciaociao-button' }, '*');
    }
}

export function showMessage() {
    const parent = getParent();
    if (parent) {
        window.parent.postMessage(
            { type: 'ciaociao-form' },
            '*',
        );
    }
}

export function onSubmitData(data: any) {
    const parent = getParent();
    if (parent) {
        window.parent.postMessage(
            { type: 'ciaociao-form-submit', data },
            '*',
        );
    }
}
